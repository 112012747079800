import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';

import { Footer, ScrollToTop, TopNavigation } from './index';
import { SignupCtaSection } from '../sections';
import SubscriptionModal from './SubscriptionModal';
import HoverableQuestions from 'components/HoverableQuestions';
import NewsletterSignup from './NewsletterSignup';

import { show, setModalTimeout } from '../store/sub_modal';
import { populate } from '../store/hoverableQuestions';
import InfoBanner from './InfoBanner/Banner';

const isBrowser = typeof window !== 'undefined';

const LS_CLOSED_HOVERABLES = `closedHoverables-${new Date().toDateString()}`;

export const query = graphql`
  {
    subModal: allDatoCmsSubscriptionsModal {
      edges {
        node {
          backgroundUrl
          callToActionText
          content
          defaultOpenDelay
          buttons {
            link
            label
          }
        }
      }
    }
    questions: allDatoCmsQuestionOfTheDay(filter: { active: { eq: true } }) {
      edges {
        node {
          fileName
          link
          shortDescription
          title
          originalId
          forceOpen
          hideFromSite
          file {
            url
          }
          fileUrl
          callToActionText
        }
      }
    }
    datoCmsSubscriptionPage {
      showAdsToSubscribedUsers
    }
    datoCmsGlobal {
      topBanner {
        ...BannerFieldsFragment
      }
      bottomBanner {
        ...BannerFieldsFragment
      }
    }
  }
`;

const Layout = ({ children }) => {
  const user = useSelector((state) => state.user.data);
  const queryResult = useStaticQuery(query);
  const data = queryResult.subModal?.edges[0]?.node;
  const questions = queryResult.questions.edges;
  const showAdsToSubscribedUsers = queryResult?.datoCmsSubscriptionPage?.showAdsToSubscribedUsers;
  const showModal = useSelector((state) => state.subModal.show);
  const modalTimeout = useSelector((state) => state.subModal.timeout);
  const dispatch = useDispatch();
  const isSubscribed = user?.subscribed ?? false;

  const [timeoutId, setTimeoutId] = useState();

  const topBanner = queryResult?.datoCmsGlobal?.topBanner;
  const bottomBanner = queryResult?.datoCmsGlobal?.bottomBanner;

  // const [scrollPosition, setScrollPosition] = useState(0);

  // Function to handle scroll events
  // const handleScroll = () => {
  //     const currentPosition = window.scrollY;
  //     setScrollPosition(currentPosition);
  // };

  // // Effect to add and remove scroll event listener
  // useEffect(() => {
  //     const savedScrollPosition = localStorage.getItem('scrollPosition');
  //     const pathname = localStorage.getItem('pathname');

  //     if (savedScrollPosition && window.location.pathname === pathname) {
  //         console.log('match found, scrolling...');
  //         window.scrollTo(0, parseInt(savedScrollPosition, 10));
  //     }

  //     // Add scroll event listener when component mounts
  //     window.addEventListener('scroll', handleScroll);
  //     localStorage.setItem('pathname', window.location.pathname);

  //     // Cleanup: remove scroll event listener when component unmounts
  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  // Effect to save scroll position to localStorage whenever it changes
  // useEffect(() => {
  //     localStorage.setItem('scrollPosition', scrollPosition.toString());
  // }, [scrollPosition]);

  React.useEffect(() => {
    const alreadyClosed = JSON.parse(localStorage.getItem(LS_CLOSED_HOVERABLES) ?? '[]');
    for (const key in localStorage) {
      if (key.substring(0, 16) == 'closedHoverables' && key !== LS_CLOSED_HOVERABLES) {
        localStorage.removeItem(key);
      }
    }
    const remainingQuestions = questions.filter(
      ({ node: question }) => !alreadyClosed.includes(question.originalId),
    );
    dispatch(populate(remainingQuestions));
  }, [questions]);

  useEffect(() => {
    if (isBrowser) {
      const openModal = localStorage.getItem('open-modal-on-load') ?? false;
      if (openModal) {
        if (!isSubscribed) dispatch(show(user));
        localStorage.removeItem('open-modal-on-load');
      }
    }
  }, []);

  useEffect(() => {
    if (timeoutId) clearTimeout(timeoutId);

    if (!modalTimeout) return;

    const newTimeoutId = setTimeout(() => {
      if (!isSubscribed) dispatch(show(user));
    }, modalTimeout * 1000);

    setTimeoutId(newTimeoutId);
  }, [modalTimeout, user]);

  useEffect(() => {
    if (!isSubscribed || showAdsToSubscribedUsers) return;

    const remove_ads = () => {
      const ads = [...document.querySelectorAll('.adsbygoogle')];
      ads.forEach((element) => element.remove());
      const ad_containers = [...document.querySelectorAll('.google-auto-placed')];
      ad_containers.forEach((el) => el.remove());
    };

    remove_ads();

    setTimeout(remove_ads, 3000);
  }, [isSubscribed]);

  useEffect(() => {
    const modalDelay = data?.defaultOpenDelay || 300;
    if (!modalTimeout) dispatch(setModalTimeout(modalDelay));
  }, [data]);

  return (
    <div>
      {/* <Messenger /> */}
      {topBanner?.map((banner) => (
        <InfoBanner key={banner.label} banner={banner} />
      ))}
      <TopNavigation />
      {bottomBanner && <InfoBanner banner={bottomBanner} />}
      <div id="custom-fuse-header" />
      <br />
      <HoverableQuestions />
      <NewsletterSignup />
      {showModal && <SubscriptionModal data={data} />}
      {children}
      <SignupCtaSection />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export { Layout };

import React from 'react';

const Secondary = ({ links }) => {
  const iconLinks = links.filter((link) => link.icon);
  const textLinks = links.filter((link) => !link.icon);

  const renderNav = (link) => (
    <a
      href={link.externalLink}
      className="navigation-link w-nav-link"
      key={link.label}
      style={{ fontSize: 12 }}
      target="_blank"
      rel="noreferrer"
    >
      {link.icon && link.icon.url && (
        <img src={link.icon.url} style={{ height: 12, marginRight: 5 }} alt="Link" />
      )}{' '}
      {link.label}
    </a>
  );
  return (
    <nav role="secondary navigation" className="nav-menu-2 w-nav-menu">
      <div className="secondary-nav">{iconLinks.map(renderNav)}</div>
      <div className="secondary-nav">{textLinks.map(renderNav)}</div>
    </nav>
  );
};

export { Secondary };
